<template>
  <svg class="w-full" id="sinwave" xmlns="http://www.w3.org/2000/svg" viewBox="-0.3 -0.6 6.6 1.2">
    <g :transform="getScale">
      <path class="sinbg" :style="getbgstyle" d="M 0 0 c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0" />
      <path class="sinfg" :style="getfgstyle" d="M 0 0 c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0
                            c 0.93461585909779 -1.1026577908436 1.0653841409022 1.1026577908436 2 0" />
    </g>
  </svg>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "sindicator",
  props:{
    max:{
      type: Number,
      required: true,
      default: 100
    },
    val:{
      type: Number,
      required: true,
      default: 100
    }
  },
  data(){
    return {
      scales:[1,0.5,0.25,0.125,0.0625,0.03125],
      offset:[7.321689605712891,14.643383979797363,29.28677749633789,58.57362365722656,117.14754486083984,234.29550170898438]
    }
  },
  computed:{
    getScale(){
      return `scale(${this.scales[this.scale]},1)`
    },
    getbgstyle(){
      const offset=this.offset[5]-this.offset[this.scale]
      return `stroke-dashoffset:${offset}`
    },
    getfgstyle(){
      const offset=this.offset[5] - this.offset[5]*this.val/this.max
      return `stroke-dashoffset:${offset}`
    },
    scale(){
      const ratio =this.val/this.max
      let calcScale=0
      for(let i=5;i>0;i--){
        if(ratio>this.scales[i]){
          calcScale++
        }
      }
      return calcScale
    }
  }
}
</script>

<style scoped>

svg path{
  stroke-width:0.5;fill:none;stroke-linecap:round; stroke-dasharray:234.29550170898438;
  transition: stroke-dashoffset 0.5s
}
svg g{
  transition: transform 1s;
}
.sinfg{ stroke:#7B5E8A}
.sinbg{ stroke:#F6F3F0}
</style>