<template>
  <div class="w-full grid grid-cols-3 gap-4 p-10 houdini">
    <div class="col-span-3 text-4xl font-bold text-left">Explore the assumptions and provide feedback</div>
    <div class="col-span-3 text-2xl font-bold text-left">1. Mobility action: Extra Day for Vacation</div>
    <div class="bg-gradient-to-br from-gradfrom to-gradto p-8 rounded-3xl">
      <div>
        <div class="text-xl text-left">Number of people reached</div>
        <div class="text-4xl underline font-bold text-center">{{ humanlogval }}</div>
        <div class="logbg"><Slider v-model="val" :step="0.1" :max="984" :min="0" /></div>
      </div>
      <div>
        <div class="text-xl text-left">People adopting</div>
        <div class="text-4xl underline font-bold text-center">{{ val2 }}%</div>
        <div class="linearbg"><Slider v-model="val2" :step="1" /></div>
      </div>
      <div>
        <div class="text-xl text-left">Improvement factor</div>
        <div class="text-4xl underline font-bold text-center">{{ val3 }}%</div>
        <div class="linearbg"><Slider v-model="val3" :step="1" /></div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="text-4xl underline font-bold text-center">{{ humanemmisions }}kg CO<sub>2</sub>/year</div>
      <div class="pt-32"><sindicator class="w-full" :max="7000000000" :val="emmissions"></sindicator></div>
    </div><span class="p-slider   hidden"><span class="p-slider-handle"></span><span class="p-slider-range"></span></span>
    <div class="col-span-3 text-2xl font-bold text-left">2. Mobility action: Bike commuting initiative</div>
    <div class="bg-gradient-to-br from-gradfrom to-gradto p-8 rounded-3xl">
      <div>
        <div class="text-xl text-left">Number of people reached</div>
        <div class="text-4xl underline font-bold text-center">{{ humanbikelogval }}</div>
        <div class="logbg2"><Slider v-model="bikeval" :step="0.1" :max="7" :min="3" /></div>
      </div>
      <div>
        <div class="text-xl text-left">People adopting</div>
        <div class="text-4xl underline font-bold text-center">{{ bikeval2 }}%</div>
        <div class="linearbg"><Slider v-model="bikeval2" :step="1" /></div>
      </div>
      <div>
        <div class="text-xl text-left">Improvement factor</div>
        <div class="text-4xl underline font-bold text-center">{{ bikeval3 }}%</div>
        <div class="linearbg"><Slider v-model="bikeval3" :step="1" /></div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="text-4xl underline font-bold text-center">{{ humanbikeemmisions }}kg CO<sub>2</sub>/year</div>
      <div class="pt-32"><sindicator class="w-full" :max="10000000" :val="bikeemmissions"></sindicator></div>
    </div><span class="p-slider   hidden"><span class="p-slider-handle"></span><span class="p-slider-range"></span></span>
  </div>
</template>

<script>
import Sindicator from "@/components/sindicator";
import Slider from 'primevue/slider';
export default {
  name: 'HelloWorld',
  components: {Sindicator, Slider},
  props: {
    msg: String
  },
  data(){
    return{
      val:0,
      val2:20,
      val3:80,
      bikeval:6,
      bikeval2:1,
      bikeval3:50
    }
  },
  computed:{
    logval(){
      if(this.val==0) return 0
      const v=Math.pow(10, (0.005+this.val/100))
      if(v<10){
        return Math.round(v)
      } else {
        return v
      }
    },
    humanlogval(){
      return new Intl.NumberFormat().format(this.logval.toPrecision(2))
    },
    humanemmisions(){
      const baseline=2000
      return new Intl.NumberFormat().format(this.emmissions* baseline)
    },
    emmissions(){
      return (this.logval*(this.val2/100)*(this.val3/100)).toPrecision(4)
    },
    bikelogval(){
      return Math.pow(10, this.bikeval)
    },
    humanbikelogval(){
      return new Intl.NumberFormat().format(this.bikelogval.toPrecision(2))
    },
    humanbikeemmisions(){
      const baseline=1500
      return new Intl.NumberFormat().format(this.bikeemmissions* baseline)
    },
    bikeemmissions(){
      return (this.bikelogval*(this.bikeval2/100)*(this.bikeval3/100)).toPrecision(4)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.houdini{
  color:#707070
}
.houdini .p-slider {
  background: #707070;
}
.houdini .p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #7B5E8A;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.houdini .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #7B5E8A;
  border-color: #7B5E8A;
}
.houdini .p-slider .p-slider-range {
  background: #7B5E8A;
}
.logbg{background-image: url('~@/assets/logbg.svg'); background-size: cover; padding:10% 13% 10% 0.95%}
.logbg2{background-image: url('~@/assets/logbg2.svg'); background-size: cover; padding:10% 13% 10% 0.95%}
.linearbg{background-image: url('~@/assets/linearbg.svg'); background-size: cover; padding:10% 13% 10% 0.95%}
</style>
